var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aRow',{attrs:{"gutter":20}},[_c('aCol',{staticStyle:{"height":"40px"},attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'person_type',
          {
            rules: [
              {
                required: true,
                message: 'Selecione qual o tipo de pessoa.',
              },
            ],
          },
        ]),expression:"[\n          'person_type',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Selecione qual o tipo de pessoa.',\n              },\n            ],\n          },\n        ]"}],staticClass:"relative",staticStyle:{"top":"-20px"},attrs:{"disabled":_vm.disableCustomerMainData,"options":_vm.personTypes}})],1),_vm._v(" "),_c('a-form-item',{staticClass:"mb-10 none"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['id']),expression:"['id']"}],staticClass:"travel-input",attrs:{"disabled":_vm.disableCustomerMainData,"placeholder":"ID"}})],1)],1),_vm._v(" "),_c('aCol',{staticStyle:{"height":"40px"},attrs:{"span":12}},[_c('a-checkbox',{staticClass:"f13 relative fw600 mb-20",on:{"change":_vm.onChangeIsInternational},model:{value:(_vm.isInternational),callback:function ($$v) {_vm.isInternational=$$v},expression:"isInternational"}},[_vm._v("\n      Cliente internacional\n    ")]),_vm._v(" "),_c('a-form-item',{staticClass:"none"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_international']),expression:"['is_international']"}]})],1)],1),_vm._v(" "),_c('aCol',{staticClass:"mt-10",attrs:{"span":24}}),_vm._v(" "),_c('aCol',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPersonType('Pessoa Jurídica')),expression:"checkPersonType('Pessoa Jurídica')"}],attrs:{"span":_vm.newFieldSizes.companyName}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Razão social ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `company_name`,
          {
            rules: [
              {
                required: _vm.checkPersonType('Pessoa Jurídica'),
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          `company_name`,\n          {\n            rules: [\n              {\n                required: checkPersonType('Pessoa Jurídica'),\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Razão social"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPersonType('Pessoa Jurídica')),expression:"checkPersonType('Pessoa Jurídica')"}],attrs:{"span":_vm.newFieldSizes.tradingName}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Nome Fantasia ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `trading_name`,
          {
            rules: [
              {
                required: _vm.checkPersonType('Pessoa Jurídica'),
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          `trading_name`,\n          {\n            rules: [\n              {\n                required: checkPersonType('Pessoa Jurídica'),\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Nome Fantasia"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPersonType('Pessoa Jurídica')),expression:"checkPersonType('Pessoa Jurídica')"}],attrs:{"span":_vm.newFieldSizes.cnpj}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" CNPJ ")]),_vm._v(" "),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"},{name:"decorator",rawName:"v-decorator",value:([
          `cnpj`,
          {
            rules: [
              {
                required: _vm.checkPersonType('Pessoa Jurídica'),
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          `cnpj`,\n          {\n            rules: [\n              {\n                required: checkPersonType('Pessoa Jurídica'),\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",attrs:{"placeholder":"CNPJ"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPersonType('Pessoa Física')),expression:"checkPersonType('Pessoa Física')"}],attrs:{"span":_vm.newFieldSizes.firstName}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Primeiro Nome ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'first_name',
          {
            rules: [
              {
                required: _vm.checkPersonType('Pessoa Física'),
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          'first_name',\n          {\n            rules: [\n              {\n                required: checkPersonType('Pessoa Física'),\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",attrs:{"disabled":_vm.disableCustomerMainData,"placeholder":"Escreva..."}},[(!_vm.disableCustomerMainData)?_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"}):_vm._e()],1)],1)],1),_vm._v(" "),_c('aCol',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPersonType('Pessoa Física')),expression:"checkPersonType('Pessoa Física')"}],attrs:{"span":_vm.newFieldSizes.lastName}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Sobrenome ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'last_name',
          {
            rules: [
              {
                required: _vm.checkPersonType('Pessoa Física'),
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          'last_name',\n          {\n            rules: [\n              {\n                required: checkPersonType('Pessoa Física'),\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",attrs:{"disabled":_vm.disableCustomerMainData,"placeholder":"Escreva..."}},[(!_vm.disableCustomerMainData)?_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"}):_vm._e()],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":_vm.newFieldSizes.email}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" E-mail ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          {
            rules: [
              {
                type: 'email',
                message: 'Escreva um e-mail válido',
              },
              {
                required: true,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          'email',\n          {\n            rules: [\n              {\n                type: 'email',\n                message: 'Escreva um e-mail válido',\n              },\n              {\n                required: true,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Escreva...","disabled":_vm.disableCustomerMainData}},[(!_vm.disableCustomerMainData)?_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"}):_vm._e()],1)],1)],1),_vm._v(" "),_c('aCol',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPersonType('Pessoa Física')),expression:"checkPersonType('Pessoa Física')"}],attrs:{"span":_vm.newFieldSizes.birthday}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Nascimento ")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
          'birthday',
          {
            rules: [
              {
                required: true,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          'birthday',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Selecione","disabled":_vm.$router.history.current.params.action === 'view',"format":"DD/MM/YYYY","showToday":false,"disabled-date":_vm.adultHoodDate},on:{"openChange":_vm.defaultDate}})],1)],1),_vm._v(" "),_c('aCol',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPersonType('Pessoa Física')),expression:"checkPersonType('Pessoa Física')"}],attrs:{"span":_vm.newFieldSizes.cpf}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" CPF ")]),_vm._v(" "),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:(!_vm.isInternational ? '###.###.###-##' : '####################'),expression:"!isInternational ? '###.###.###-##' : '####################'"},{name:"decorator",rawName:"v-decorator",value:([
          'cpf',
          {
            rules: [
              {
                required: true,
                min: 9,
                message: 'Preencha todos números.',
              },
            ],
          },
        ]),expression:"[\n          'cpf',\n          {\n            rules: [\n              {\n                required: true,\n                min: 9,\n                message: 'Preencha todos números.',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",class:_vm.allowEditCpf ? '' : 'readonly',attrs:{"placeholder":"Escreva..."},on:{"blur":_vm.verifyTypedCPF}},[(_vm.$router.history.current.params.action !== 'view')?_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"}):_vm._e()],1)],1)],1),_vm._v(" "),(!_vm.isInternational)?_c('aCol',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPersonType('Pessoa Física')),expression:"checkPersonType('Pessoa Física')"}],attrs:{"span":_vm.newFieldSizes.rg}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" RG ")]),_vm._v(" "),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:([
          '##.###.###',
          '#.###.###',
          '###.###.###-##',
          '###.###.###.###-##',
        ]),expression:"[\n          '##.###.###',\n          '#.###.###',\n          '###.###.###-##',\n          '###.###.###.###-##',\n        ]"},{name:"decorator",rawName:"v-decorator",value:([
          'rg',
          {
            rules: [
              {
                required: false,
                message: 'Obrigatório',
              },
              { min: 9, message: 'Preencha todos números' },
            ],
          },
        ]),expression:"[\n          'rg',\n          {\n            rules: [\n              {\n                required: false,\n                message: 'Obrigatório',\n              },\n              { min: 9, message: 'Preencha todos números' },\n            ],\n          },\n        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Escreva...","disabled":_vm.$router.history.current.params.action === 'view'}},[(_vm.$router.history.current.params.action !== 'view')?_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"}):_vm._e()],1)],1)],1):_vm._e(),_vm._v(" "),(!_vm.isInternational)?_c('aCol',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPersonType('Pessoa Física')),expression:"checkPersonType('Pessoa Física')"}],attrs:{"span":_vm.newFieldSizes.rgEmissor}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Órgão Emissor ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'rg_emissor',
          {
            rules: [
              {
                required: false,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          'rg_emissor',\n          {\n            rules: [\n              {\n                required: false,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",attrs:{"disabled":_vm.$router.history.current.params.action === 'view',"type":"text","placeholder":"Escreva..."}},[(_vm.$router.history.current.params.action !== 'view')?_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"}):_vm._e()],1)],1)],1):_vm._e(),_vm._v(" "),(!_vm.isInternational)?_c('aCol',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPersonType('Pessoa Física')),expression:"checkPersonType('Pessoa Física')"}],attrs:{"span":_vm.newFieldSizes.rgState}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Estado de emissão ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'rg_state',
          {
            rules: [
              {
                required: false,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          'rg_state',\n          {\n            rules: [\n              {\n                required: false,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",style:('width: 100%'),attrs:{"disabled":_vm.$router.history.current.params.action === 'view',"show-search":"","placeholder":"Selecione"}},_vm._l((_vm.states),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.initials}},[_vm._v("\n          "+_vm._s(item.initials.toUpperCase())+"\n        ")])}),1)],1)],1):_vm._e(),_vm._v(" "),_c('aCol',{attrs:{"span":3}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Código país ")]),_vm._v(" "),_c('aSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'country_code_fake',
          {
            initialValue: 'Brasil-55',
            rules: [
              {
                required: true,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          'country_code_fake',\n          {\n            initialValue: 'Brasil-55',\n            rules: [\n              {\n                required: true,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input w100",attrs:{"dropdownMatchSelectWidth":false,"filterOption":_vm.filterOption,"placeholder":"Selecione","showSearch":"","allowClear":""},on:{"change":_vm.onChangeCountryCode}},_vm._l((_vm.countryCodes),function(item,i){return _c('aSelectOption',{key:i,attrs:{"value":`${item.pais}-${item.ddi}`,"country":item.pais}},[_c('img',{staticClass:"mr-5",attrs:{"width":"20","src":item.img,"alt":"flag"}}),_vm._v(" "),_c('b',[_vm._v("+"+_vm._s(item.ddi))]),_vm._v(" "),_c('span',{staticClass:"ml-10 f11",staticStyle:{"color":"#aaa"}},[_vm._v(_vm._s(item.pais))])])}),1)],1),_vm._v(" "),_c('a-form-item',{staticClass:"none"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['country_code', { initialValue: '55' }]),expression:"['country_code', { initialValue: '55' }]"}],staticClass:"travel-input",attrs:{"placeholder":"Código país"}})],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":_vm.newFieldSizes.mobilePhone}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Celular ")]),_vm._v(" "),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:(
          !_vm.isInternational ? '(##) # ####-####' : '#####################'
        ),expression:"\n          !isInternational ? '(##) # ####-####' : '#####################'\n        "},{name:"decorator",rawName:"v-decorator",value:([
          'mobile_phone',
          {
            rules: [
              {
                required: true,
                message: 'Obrigatório',
              },
              {
                required: true,
                min: !_vm.isInternational ? 16 : 9,
                message: 'Preencha todos números',
              },
            ],
          },
        ]),expression:"[\n          'mobile_phone',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Obrigatório',\n              },\n              {\n                required: true,\n                min: !isInternational ? 16 : 9,\n                message: 'Preencha todos números',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Escreva...","disabled":_vm.disableCustomerMainData}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":_vm.newFieldSizes.phone}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Telefone fixo ")]),_vm._v(" "),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:(!_vm.isInternational ? '(##) ####-####' : '############'),expression:"!isInternational ? '(##) ####-####' : '############'"},{name:"decorator",rawName:"v-decorator",value:([
          'phone',
          {
            rules: [
              {
                required: false,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          'phone',\n          {\n            rules: [\n              {\n                required: false,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Escreva...","disabled":_vm.$router.history.current.params.action === 'view'}},[(_vm.$router.history.current.params.action !== 'view')?_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"}):_vm._e()],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":_vm.newFieldSizes.status}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Status ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'status',
          {
            initialValue: 'Ativo',
            rules: [
              {
                required: false,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          'status',\n          {\n            initialValue: 'Ativo',\n            rules: [\n              {\n                required: false,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",style:('width: 100%'),attrs:{"disabled":_vm.$router.history.current.params.action === 'view',"show-search":"","placeholder":"Selecione"}},_vm._l((_vm.status),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v("\n          "+_vm._s(item.label)+"\n        ")])}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }