<template>
  <aRow :gutter="20">
    <aCol :span="12" style="height: 40px">
      <a-form-item class="travel-input-outer">
        <a-radio-group
          class="relative"
          style="top: -20px"
          :disabled="disableCustomerMainData"
          v-decorator="[
            'person_type',
            {
              rules: [
                {
                  required: true,
                  message: 'Selecione qual o tipo de pessoa.',
                },
              ],
            },
          ]"
          :options="personTypes"
        />
      </a-form-item>

      <a-form-item class="mb-10 none">
        <a-input
          :disabled="disableCustomerMainData"
          class="travel-input"
          placeholder="ID"
          v-decorator="['id']"
        >
        </a-input>
      </a-form-item>
    </aCol>

    <aCol :span="12" style="height: 40px">
      <a-checkbox
        v-model="isInternational"
        @change="onChangeIsInternational"
        class="f13 relative fw600 mb-20"
      >
        Cliente internacional
      </a-checkbox>

      <a-form-item class="none">
        <a-input v-decorator="['is_international']"> </a-input>
      </a-form-item>
    </aCol>

    <aCol class="mt-10" :span="24"></aCol>

    <aCol
      v-show="checkPersonType('Pessoa Jurídica')"
      :span="newFieldSizes.companyName"
    >
      <a-form-item class="travel-input-outer">
        <label for :class="'filled'"> Razão social </label>

        <a-input
          class="travel-input"
          placeholder="Razão social"
          v-decorator="[
            `company_name`,
            {
              rules: [
                {
                  required: checkPersonType('Pessoa Jurídica'),
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-icon slot="prefix" type="field-svg" />
        </a-input>
      </a-form-item>
    </aCol>

    <aCol
      v-show="checkPersonType('Pessoa Jurídica')"
      :span="newFieldSizes.tradingName"
    >
      <a-form-item class="travel-input-outer">
        <label for :class="'filled'"> Nome Fantasia </label>

        <a-input
          class="travel-input"
          placeholder="Nome Fantasia"
          v-decorator="[
            `trading_name`,
            {
              rules: [
                {
                  required: checkPersonType('Pessoa Jurídica'),
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-icon slot="prefix" type="field-svg" />
        </a-input>
      </a-form-item>
    </aCol>

    <aCol
      v-show="checkPersonType('Pessoa Jurídica')"
      :span="newFieldSizes.cnpj"
    >
      <a-form-item class="travel-input-outer">
        <label for :class="'filled'"> CNPJ </label>

        <a-input
          class="travel-input"
          placeholder="CNPJ"
          v-mask="'##.###.###/####-##'"
          v-decorator="[
            `cnpj`,
            {
              rules: [
                {
                  required: checkPersonType('Pessoa Jurídica'),
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-icon slot="prefix" type="field-svg" />
        </a-input>
      </a-form-item>
    </aCol>

    <aCol
      v-show="checkPersonType('Pessoa Física')"
      :span="newFieldSizes.firstName"
    >
      <a-form-item class="travel-input-outer">
        <label for :class="'filled'"> Primeiro Nome </label>
        <a-input
          :disabled="disableCustomerMainData"
          class="travel-input"
          placeholder="Escreva..."
          v-decorator="[
            'first_name',
            {
              rules: [
                {
                  required: checkPersonType('Pessoa Física'),
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-icon
            v-if="!disableCustomerMainData"
            slot="prefix"
            type="field-svg"
          />
        </a-input>
      </a-form-item>
    </aCol>

    <aCol
      v-show="checkPersonType('Pessoa Física')"
      :span="newFieldSizes.lastName"
    >
      <a-form-item class="travel-input-outer">
        <label for :class="'filled'"> Sobrenome </label>
        <a-input
          :disabled="disableCustomerMainData"
          class="travel-input"
          placeholder="Escreva..."
          v-decorator="[
            'last_name',
            {
              rules: [
                {
                  required: checkPersonType('Pessoa Física'),
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-icon
            v-if="!disableCustomerMainData"
            slot="prefix"
            type="field-svg"
          />
        </a-input>
      </a-form-item>
    </aCol>

    <aCol :span="newFieldSizes.email">
      <a-form-item class="travel-input-outer">
        <label for :class="'filled'"> E-mail </label>
        <a-input
          class="travel-input"
          placeholder="Escreva..."
          :disabled="disableCustomerMainData"
          v-decorator="[
            'email',
            {
              rules: [
                {
                  type: 'email',
                  message: 'Escreva um e-mail válido',
                },
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-icon
            v-if="!disableCustomerMainData"
            slot="prefix"
            type="field-svg"
          />
        </a-input>
      </a-form-item>
    </aCol>

    <aCol
      v-show="checkPersonType('Pessoa Física')"
      :span="newFieldSizes.birthday"
    >
      <a-form-item class="travel-input-outer">
        <label for :class="'filled'"> Nascimento </label>
        <a-date-picker
          class="travel-input"
          placeholder="Selecione"
          :disabled="$router.history.current.params.action === 'view'"
          format="DD/MM/YYYY"
          v-mask="'##/##/####'"
          :showToday="false"
          :disabled-date="adultHoodDate"
          @openChange="defaultDate"
          v-decorator="[
            'birthday',
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
        </a-date-picker>
      </a-form-item>
    </aCol>

    <aCol v-show="checkPersonType('Pessoa Física')" :span="newFieldSizes.cpf">
      <a-form-item class="travel-input-outer">
        <label for :class="'filled'"> CPF </label>
        <a-input
          :class="allowEditCpf ? '' : 'readonly'"
          class="travel-input"
          placeholder="Escreva..."
          v-mask="!isInternational ? '###.###.###-##' : '####################'"
          @blur="verifyTypedCPF"
          v-decorator="[
            'cpf',
            {
              rules: [
                {
                  required: true,
                  min: 9,
                  message: 'Preencha todos números.',
                },
              ],
            },
          ]"
        >
          <a-icon
            v-if="$router.history.current.params.action !== 'view'"
            slot="prefix"
            type="field-svg"
          />
        </a-input>
      </a-form-item>
    </aCol>

    <aCol
      v-if="!isInternational"
      v-show="checkPersonType('Pessoa Física')"
      :span="newFieldSizes.rg"
    >
      <a-form-item class="travel-input-outer">
        <label for :class="'filled'"> RG </label>
        <a-input
          class="travel-input"
          placeholder="Escreva..."
          :disabled="$router.history.current.params.action === 'view'"
          v-mask="[
            '##.###.###',
            '#.###.###',
            '###.###.###-##',
            '###.###.###.###-##',
          ]"
          v-decorator="[
            'rg',
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
                { min: 9, message: 'Preencha todos números' },
              ],
            },
          ]"
        >
          <a-icon
            v-if="$router.history.current.params.action !== 'view'"
            slot="prefix"
            type="field-svg"
          />
        </a-input>
      </a-form-item>
    </aCol>

    <aCol
      v-if="!isInternational"
      v-show="checkPersonType('Pessoa Física')"
      :span="newFieldSizes.rgEmissor"
    >
      <a-form-item class="travel-input-outer">
        <label for :class="'filled'"> Órgão Emissor </label>
        <a-input
          class="travel-input"
          :disabled="$router.history.current.params.action === 'view'"
          type="text"
          placeholder="Escreva..."
          v-decorator="[
            'rg_emissor',
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-icon
            v-if="$router.history.current.params.action !== 'view'"
            slot="prefix"
            type="field-svg"
          />
        </a-input>
      </a-form-item>
    </aCol>

    <aCol
      v-if="!isInternational"
      v-show="checkPersonType('Pessoa Física')"
      :span="newFieldSizes.rgState"
    >
      <a-form-item class="travel-input-outer">
        <label for :class="'filled'"> Estado de emissão </label>
        <a-select
          class="travel-input"
          :disabled="$router.history.current.params.action === 'view'"
          show-search
          placeholder="Selecione"
          :style="'width: 100%'"
          v-decorator="[
            'rg_state',
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-select-option
            v-for="(item, index) of states"
            :key="index"
            :value="item.initials"
          >
            {{ item.initials.toUpperCase() }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </aCol>

    <aCol :span="3">
      <a-form-item class="travel-input-outer">
        <label for :class="'filled'"> Código país </label>
        <aSelect
          class="travel-input w100"
          :dropdownMatchSelectWidth="false"
          :filterOption="filterOption"
          @change="onChangeCountryCode"
          placeholder="Selecione"
          showSearch
          allowClear
          v-decorator="[
            'country_code_fake',
            {
              initialValue: 'Brasil-55',
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <aSelectOption
            v-for="(item, i) in countryCodes"
            :value="`${item.pais}-${item.ddi}`"
            :country="item.pais"
            :key="i"
          >
            <img width="20" :src="item.img" alt="flag" class="mr-5" />
            <b>+{{ item.ddi }}</b>
            <span class="ml-10 f11" style="color: #aaa">{{ item.pais }}</span>
          </aSelectOption>
        </aSelect>
      </a-form-item>

      <a-form-item class="none">
        <a-input
          class="travel-input"
          placeholder="Código país"
          v-decorator="['country_code', { initialValue: '55' }]"
        >
        </a-input>
      </a-form-item>
    </aCol>

    <aCol :span="newFieldSizes.mobilePhone">
      <a-form-item class="travel-input-outer">
        <label for :class="'filled'"> Celular </label>
        <a-input
          class="travel-input"
          placeholder="Escreva..."
          :disabled="disableCustomerMainData"
          v-mask="
            !isInternational ? '(##) # ####-####' : '#####################'
          "
          v-decorator="[
            'mobile_phone',
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
                {
                  required: true,
                  min: !isInternational ? 16 : 9,
                  message: 'Preencha todos números',
                },
              ],
            },
          ]"
        >
          <a-icon slot="prefix" type="field-svg" />
        </a-input>
      </a-form-item>
    </aCol>

    <aCol :span="newFieldSizes.phone">
      <a-form-item class="travel-input-outer">
        <label for :class="'filled'"> Telefone fixo </label>
        <a-input
          class="travel-input"
          placeholder="Escreva..."
          v-mask="!isInternational ? '(##) ####-####' : '############'"
          :disabled="$router.history.current.params.action === 'view'"
          v-decorator="[
            'phone',
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-icon
            v-if="$router.history.current.params.action !== 'view'"
            slot="prefix"
            type="field-svg"
          />
        </a-input>
      </a-form-item>
    </aCol>

    <aCol :span="newFieldSizes.status">
      <a-form-item class="travel-input-outer">
        <label for :class="'filled'"> Status </label>
        <a-select
          class="travel-input"
          :disabled="$router.history.current.params.action === 'view'"
          show-search
          placeholder="Selecione"
          :style="'width: 100%'"
          v-decorator="[
            'status',
            {
              initialValue: 'Ativo',
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
        >
          <a-select-option
            v-for="(item, index) of status"
            :key="index"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </aCol>
  </aRow>
</template>

<script>
import { format, subYears } from "date-fns";
import countryCodes from "@/json/countryCodeList";

export default {
  name: "CustomerFormDefaultData",
  props: {
    customerForm: Object,
    customer: Object,
    newFieldSizes: Object,
    disableCustomerMainData: Boolean,
    allowEditCpf: Boolean,
  },
  data() {
    return {
      isInternational: false,
      countryCodes,
      personTypes: [
        { label: "Pessoa Física", value: "Pessoa Física" },
        { label: "Pessoa Jurídica", value: "Pessoa Jurídica" },
      ],
      status: [
        { label: "Ativo", value: "Ativo" },
        { label: "Desativado", value: "Desativado" },
      ],
      states: [
        { name: "Acre", initials: "AC" },
        { name: "Alagoas", initials: "AL" },
        { name: "Amapá", initials: "AP" },
        { name: "Amazonas", initials: "AM" },
        { name: "Bahia", initials: "BA" },
        { name: "Ceará", initials: "CE" },
        { name: "Distrito Federal", initials: "DF" },
        { name: "Espírito Santo", initials: "ES" },
        { name: "Goiás", initials: "GO" },
        { name: "Maranhão", initials: "MA" },
        { name: "Mato Grosso", initials: "MT" },
        { name: "Mato Grosso do Sul", initials: "MS" },
        { name: "Minas Gerais", initials: "MG" },
        { name: "Pará", initials: "PA" },
        { name: "Paraíba", initials: "PB" },
        { name: "Paraná", initials: "PR" },
        { name: "Pernambuco", initials: "PE" },
        { name: "Piauí", initials: "PI" },
        { name: "Rio de Janeiro", initials: "RJ" },
        { name: "Rio Grande do Norte", initials: "RN" },
        { name: "Rio Grande do Sul", initials: "RS" },
        { name: "Rondônia", initials: "RO" },
        { name: "Roraima", initials: "RR" },
        { name: "Santa Catarina", initials: "SC" },
        { name: "São Paulo", initials: "SP" },
        { name: "Sergipe", initials: "SE" },
        { name: "Tocantins", initials: "TO" },
      ],
    };
  },
  mounted() {
    if (this.customer == undefined)
      this.customerForm.setFieldsValue({
        person_type: "Pessoa Física",
        country_code: "55",
      });

    this.customerForm.setFieldsValue({
      person_type: this.customer.person_type,
    });

    setTimeout(() => {
      console.log(this.customer.cnpj);
      this.customerForm.setFieldsValue(this.customer);
      setTimeout(() => {
        this.isInternational =
          this.customer.is_international == 1 ? true : false;
        this.customerForm.setFieldsValue({
          country_code_fake: this.customer.country_code_fake,
        });
      }, 200);
    }, 200);
  },
  methods: {
    onChangeCountryCode(e) {
      this.customerForm.setFieldsValue({
        country_code: e ? e.split("-")[1] : "",
      });
    },
    onChangeIsInternational(e) {
      this.customerForm.setFieldsValue({
        is_international: e.target.checked,
      });
    },
    checkPersonType(personType) {
      return this.customerForm.getFieldValue("person_type") == personType
        ? true
        : false;
    },
    filterOption(inputValue, option) {
      return option.data.attrs.country
        .toLowerCase()
        .startsWith(inputValue.toLowerCase());
    },
    adultHoodDate(current) {
      return current && current > subYears(new Date(), 18);
    },
    defaultDate() {
      setTimeout(() => {
        this.customerForm.setFieldsValue({
          birthday: this.customerForm.getFieldValue("birthday")
            ? this.customerForm.getFieldValue("birthday")
            : format(subYears(new Date(), 18), "MM/dd/yyyy"),
        });
      }, 1);
    },
    verifyTypedCPF(val) {
      // Remover caracteres especiais do CPF
      let cpf = val.target.value ? val.target.value.replace(/\D/g, "") : "";

      if (!this.isInternational) {
        if (!/^\d{11}$/.test(cpf)) {
          this.customerForm.setFieldsValue({
            [`cpf`]: undefined,
          });
          return false;
        }

        if (/^(\d)\1{10}$/.test(cpf)) {
          this.customerForm.setFieldsValue({
            [`cpf`]: undefined,
          });

          return false;
        }

        if (
          [
            "00000000000",
            "11111111111",
            "00011122233",
            "22222222222",
            "33333333333",
            "44444444444",
            "55555555555",
            "66666666666",
            "77777777777",
            "88888888888",
            "99999999999",
          ].includes(cpf)
        ) {
          this.customerForm.setFieldsValue({
            [`cpf`]: undefined,
          });

          return false;
        }
      }

      return true;
    },
  },
};
</script>

<style lang="sass">
.person-type
  height: 30px
  margin-top: -18px
  margin-bottom: 36px
  .ant-radio-wrapper
    font-size: 13px !important
    opacity: 1 !important
    position: relative !important
    color: #666 !important
    width: auto
    font-weight: 400
</style>
